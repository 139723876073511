import { Injectable} from '@angular/core';
@Injectable()
export class ConfigService {

  protected configured = false;

  constructor() {

  }

  public configure(val) {
    this.configured = val;
  }

  public isConfigured() {
    return this.configured;
  }
}
