import {APP_BOOTSTRAP_LISTENER, ComponentRef, CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HopalongComponent} from '../components/visualisations/hopalong.component';
import {CloudComponent} from '../components/visualisations/cloud.component';
import {HedgehogComponent} from '../components/visualisations/hedgehog.component';
import {LotusComponent} from '../components/visualisations/lotus.component';
import {AngelsComponent} from '../components/visualisations/angels.component';
import {routing} from './visualizer.routing';
import {FormsModule} from '@angular/forms';



@NgModule({
  declarations: [
    CloudComponent,
    HedgehogComponent,
    LotusComponent,
    AngelsComponent,
    HopalongComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    routing
  ],
  exports: [
    CloudComponent,
    HedgehogComponent,
    LotusComponent,
    AngelsComponent,
    HopalongComponent,
  ],
  schemas:      [ NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA ]
})
export class VisualizerModule { }
