import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {TokenService} from './token.service';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
export const API_URL = 'https://api.soundcloud.com/';
const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    'accept': 'application/json; charset=utf-8'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  redirectUrl = '';

  private static handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      console.error(error);
    }
    return throwError('Something bad happened; please try again later.');
  }

  private static log(message: string): any {
    console.log(message);
  }

  constructor(private http: HttpClient, private tokenService: TokenService) { }

  login(): Observable<any> {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    const body: URLSearchParams = new URLSearchParams()
    body.set('client_id', environment.oauth.client)
    body.set('client_secret', environment.oauth.secret)
    body.set('grant_type', 'client_credentials')
    ;

    return this.http.post<any>(API_URL + 'oauth2/token', body, HTTP_OPTIONS)
        .pipe(
            tap(res => {
              AuthService.log(res);
              this.tokenService.saveToken(res.access_token);
              this.tokenService.saveRefreshToken(res.refresh_token);
            }),
            catchError(AuthService.handleError)
        );
  }

  refreshToken(refreshData: any): Observable<any> {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    const body: URLSearchParams = new URLSearchParams();
    body.set('refresh_token', refreshData.refresh_token);
    body.set('grant_type', 'refresh_token');
    return this.http.post<any>(API_URL + 'oauth2/token', body.toString(), HTTP_OPTIONS)
        .pipe(
            tap(res => {
              this.tokenService.saveToken(res.access_token);
              this.tokenService.saveRefreshToken(res.refresh_token);
            }),
            catchError(AuthService.handleError)
        );
  }

  logout(): void {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
  }

  register(data: any): Observable<any> {
    return this.http.post<any>(API_URL + 'oauth/signup', data)
        .pipe(
            tap(_ => AuthService.log('register')),
            catchError(AuthService.handleError)
        );
  }

  secured(): Observable<any> {
    return this.http.get<any>(API_URL + 'secret')
        .pipe(catchError(AuthService.handleError));
  }

  get(path, absolute: boolean = false): Observable<any>
  {
    if (this.tokenService.getToken() === null ) {
      this.login();
    }
    const options = {
      headers: new HttpHeaders({
        'Authorization': 'OAuth' + this.tokenService.getToken(),
      })
    };
    const url = (absolute)? path: API_URL + path;
    return this.http.get<any>(url, options)
        .pipe(
            tap(_ => AuthService.log('get')),
            catchError(AuthService.handleError)
        );
  }

  async getAudio(path, absolute: boolean = false) {
    const result = await fetch(path, {
      headers: {
        Authorization: 'OAuth' + this.tokenService.getToken()
      }
    });

    const blob = await result.blob();

    if (blob) {
      return URL.createObjectURL(blob);
    }
  }
}
