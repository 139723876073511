import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { PlaylistService } from '../services/playlist.service';
import {AudioService} from '../services/audio.service';
import {NavigationEnd, NavigationStart, Route, Router, RouterLink, RouterOutlet} from '@angular/router';
import {AppRoutingModule} from '../appRouting.module';
import {PlayerService} from '../services/player.service';
import {slideInAnimation} from '../animations';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {faArrowLeft, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {ConfigService} from '../services/config.service';


@Component({
  selector: 'app-root',
  templateUrl: '../views/app.component.html',
  styleUrls: []
})

export class AppComponent implements OnInit {
  constructor(
    public configService: ConfigService,
    public router: Router
  ) {
    this.router.events.subscribe( (event) => {
      if (event instanceof NavigationStart) {
        if (event.url !== '/') {
          this.configService.configure(true);
        } else {
          this.configService.configure(false);
        }
      }
    });
  }

  ngOnInit() {

  }
  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
}
