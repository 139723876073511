import {Component} from '@angular/core';
import {AbstractWebglViewComponent} from './AbstractWebglView.abstract';
import * as THREE from 'three';
import { BloomPass } from 'three/examples/jsm/postprocessing/BloomPass.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import {ShaderPass} from 'three/examples/jsm/postprocessing/ShaderPass';
import {UnrealBloomPass} from 'three/examples/jsm/postprocessing/UnrealBloomPass';
declare let THREEx: any;

@Component({
  templateUrl: '../../views/visualisations/generic.component.html',
})
export class HedgehogComponent extends AbstractWebglViewComponent {
  composer: EffectComposer;
  object: THREE.Object3D;
  widthSegCount = 15;
  heightSegCount = 17;
  lights = [];
  sphere;
  vertexChoiceStep = 6;
  initialPositionsSaved = false;
  vertexCount = this.widthSegCount * this.heightSegCount;
  currentPositionSpeed = new THREE.Vector3();
  lightAngleDestination = 0.0;
  lightAngleSpeed = 0.0;
  lightAngle = 0.0;
  protected shaderExtras = {
    screen: {
      uniforms: {
        tDiffuse: {type: 't', value: 1, texture: { value: new THREE.TextureLoader().load( 'assets/sprite/spark.png' ) }},
        opacity: {type: 'f', value: 1.0}
      },
      vertexShader: [
        'varying vec2 vUv;',
        'void main() {',
        'vUv = vec2( uv.x, 1.0 - uv.y );',
        'gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );',
        '}'
      ].join('\n'),
      fragmentShader: [
        'uniform float opacity;',
        'uniform sampler2D tDiffuse;',
        'varying vec2 vUv;',
        'void main() {',
        'vec4 texel = texture2D( tDiffuse, vUv );',
        'gl_FragColor = opacity * texel;',
        '}'
      ].join('\n')
    }
  };

  scene_init (renderer) {
    this.scene = new THREE.Scene();

    // put a camera in the scene
    const width = this.element.offsetWidth,
      height = this.element.offsetHeight;
    this.camera  = new THREE.PerspectiveCamera(35, width / height, 1, 10000 );
    this.camera.position.set(0, 0, 5);
    this.scene.add(this.camera);

    // transparently support window resize
    THREEx.WindowResize.bind(renderer, this.camera);

    const ambientColor = (0.15) * 0xffffff;
    const light = new THREE.AmbientLight( ambientColor );
    this.scene.add( light );
    this.lights.push(light);

    const light4 = new THREE.DirectionalLight( Math.random() * 0.01 * ambientColor );
    light4.position.set( 0.0, 0.0, 1.0 ).normalize().multiplyScalar(1.2);
    this.scene.add( light4 );
    this.lights.push(light4);

    const light5 = new THREE.PointLight( 0xff0000 );
    light5.position.set( 1.0, 0.0, -1.0 ).normalize().multiplyScalar(1.2);
    this.scene.add( light5 );
    this.lights.push(light5);


    // this.consolelog(this.sphere.geometry.initialVertices[highIndex]);
    const geometry  = new THREE.SphereGeometry(1, this.widthSegCount, this.heightSegCount );
    const material  = new THREE.MeshLambertMaterial({ color: 0xffffff}); // Math.max(Math.random()*0.2+0.2, 1.0) *
    // material.shading = THREE.FlatShading;
    this.sphere  = new THREE.Mesh( geometry, material );
    this.sphere.scale.x = 0.2;
    this.sphere.scale.y = 0.2;
    this.sphere.scale.z = 0.2;
    this.sphere.position.y = 0.2;
    this.sphere.rotation.x = Math.PI;


    this.scene.add(this.sphere);
    // define the stack of passes for postProcessing


    this.composer = new EffectComposer(renderer);
    this.composer.setSize(window.innerWidth, window.innerHeight);
    renderer.autoClear = false;
    const renderModel = new RenderPass(this.scene, this.camera);
    this.composer.addPass(renderModel);

    const bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1, 0, 0 );
    this.composer.addPass(bloomPass);

    const effectScreen = new ShaderPass(this.shaderExtras[ 'screen' ]);
    effectScreen.renderToScreen = true;
    this.composer.addPass(effectScreen);

    window.addEventListener('resize', this.onWindowResize, false);

  }

  render(renderer, time_delta) {
    // variable which is increase by Math.PI every seconds - usefull for animation
    const PIseconds = Date.now() * Math.PI;

    const spectrum = Array.from(this.audioService.get3DFreqData(0, false));
    const division = 3;
    const spectrumPivot = spectrum.length / division;
    const lowSpectrum = spectrum.slice(0, spectrumPivot);
    const highSpectrum = spectrum.slice(spectrumPivot, spectrum.length);

    const verticesPerSpectrum = spectrum.length / this.vertexCount;
    if (!this.initialPositionsSaved) {
      // Merge similar vertices in our mesh
      this.sphere.geometry.mergeVertices();
      this.vertexCount = this.sphere.geometry.vertices.length;

      // Add new properties of the geometry for our animation
      this.sphere.geometry.positionSpeed = [];
      this.sphere.geometry.initialVertices = [];

      for (let i = 0; i < this.vertexCount ; i++) { // Copy our initial positions
        this.sphere.geometry.initialVertices.push(this.sphere.geometry.vertices[i].clone());
      }

      this.initialPositionsSaved = true;
    }
    let lowIndex;
    let highIndex;

    for (let i = 1; i < this.vertexCount ; i += this.vertexChoiceStep) {
      lowIndex = i - this.vertexChoiceStep;
      highIndex = i;

      // this.consolelog(lowIndex, highIndex);

      const lowPivot = lowIndex * verticesPerSpectrum;
      const highPivot = highIndex * verticesPerSpectrum;
      const spectrumSlice = spectrum.slice(lowPivot, highPivot);
      // this.consolelog(spectrumSlice);

      // let vertexShift = ((spectrum.length > 0 && !isNaN(this.avg(spectrumSlice))) ?
      //   this.avg(spectrumSlice) / (0.1 + Math.max(...spectrumSlice)) :
      //   0.0
      // );
      const vertexShift = ((spectrum.length > 0 && !isNaN(this.avg(spectrumSlice))) ?
          this.avg(spectrumSlice) / 255 :
          0.0
      );
      // this.consolelog(spectrum[i] / (512 / 32));
      // this.consolelog(vertexShift);
      // if (this.avg(spectrumSlice) != 0 && (spectrum.length > 0 && !isNaN(this.avg(spectrumSlice))) ) {
      //   this.consolelog(spectrumSlice, this.avg(spectrumSlice), (0.1 + Math.max(...spectrumSlice)), vertexShift);
      // }

      // vertexShift = vertexShift * 1000000 - (Math.floor(vertexShift * 1000000));
      // this.consolelog(vertexShift);
      // let vertexPosition = this.sphere.geometry.vertices[highIndex];

      this.currentPositionSpeed.multiplyScalar(0.0);
      this.currentPositionSpeed.add(this.sphere.geometry.initialVertices[highIndex]);
      this.currentPositionSpeed.multiplyScalar( 1 + vertexShift * 10 );
      this.currentPositionSpeed.sub(this.sphere.geometry.vertices[highIndex]);
      this.sphere.geometry.positionSpeed[highIndex] = this.currentPositionSpeed;
      this.sphere.geometry.vertices[highIndex].add(this.sphere.geometry.positionSpeed[highIndex]);

      this.sphere.geometry.verticesNeedUpdate = true;
      this.sphere.geometry.normalsNeedUpdate = true;
    }
    this.sphere.rotation.y += (!isNaN(this.avg(spectrum)) ? (this.avg(spectrum) / 255) : 0.0);
    this.sphere.rotation.y += 0.0001;
    this.sphere.geometry.__dirtyVertices = true;
    // THREE.BufferGeometryUtils.mirrorX(this.sphere.geometry);
    // Animate all lights in one cycle
    this.lights.forEach((light, idx) => {
      if (light instanceof THREE.PointLight) {

        const sum = array => array.reduce(function(a, b) { return a + b; }, 0);
        const avg = array => 1.0 * sum(array) / array.length;
        const spectrumLowSlice = spectrum.slice(spectrum.length - spectrumPivot, spectrum.length);
        const spectrumLowVal = (avg(spectrumLowSlice)) / (Math.max(...spectrumLowSlice) + 0.1);
        this.lightAngleDestination = (!isNaN(spectrumLowVal)) ? spectrumLowVal * 10.0 : 0.0;
        this.lightAngleSpeed = (this.lightAngleDestination - this.lightAngle);
        this.lightAngleSpeed /= 4.0;
        this.lightAngleSpeed = Math.min(Math.max(this.lightAngleSpeed, -0.4), 0.4);

        this.lightAngle += this.lightAngleSpeed;

        light.position.set(Math.cos(this.lightAngle), 0.0, Math.sin(this.lightAngle)).normalize().multiplyScalar(2);
      } else if (light instanceof THREE.DirectionalLight) {

      }
    });

    // actually render the scene
    renderer.clear();

    if (this.composer) {
      this.composer.render();
    } else {
      renderer.render( this.scene, this.camera );
    }

  }

  sum(array) {
    return array.reduce(function(a, b) { return a + b; }, 0);
  }

  avg(array) {
    return 1.0 * this.sum(array) / array.length;
  }


}
