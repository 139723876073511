import {EventEmitter, Injectable, Output} from '@angular/core';
import { PlaylistService } from './playlist.service';
@Injectable()
export class AudioService {
  @Output() audioReady = new EventEmitter();
  // private soundmanager: any = require('soundmanager2');
  public audioContext: AudioContext;
  public analyser: AnalyserNode;
  public analyser3D: AnalyserNode;
  public distortion: WaveShaperNode;
  public gainNode: GainNode;
  public biquadFiler: BiquadFilterNode;
  public convolver: ConvolverNode;
  public volume: any;
  public streamData = new Uint8Array(128);
  public javascriptNode: ScriptProcessorNode;
  public isInit = false;
  public sourceNode: MediaElementAudioSourceNode;
  merger: any = 8;
  gain: any = 1.5;
  // private playlistService: PlaylistService;
  constructor() {
    // window.AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext || window.msAudioContext;
    try {
      this.audioContext = new AudioContext();
      this.javascriptNode = this.audioContext.createScriptProcessor(1024, 1, 1);
    } catch (e) {
      console.error('!Your browser does not support AudioContext');
      console.log(e);
    }
  }

  decode(player: HTMLAudioElement) {
    if (!this.isInit) {
      this.isInit = true;
      this.sourceNode = this.audioContext.createMediaElementSource(player);
      let analyser3D = this.audioContext.createAnalyser();
      this.sourceNode.connect(analyser3D);
      this.analyser3D = analyser3D;
      let analyser = this.audioContext.createAnalyser();
      this.sourceNode.connect(analyser);
      this.analyser = analyser;
      this.sourceNode.connect(this.audioContext.destination);
      this.audioReady.emit();
    }
  }

  getFreqData(meterNum, size)
  {
    // let data = new Float32Array(this.audioService.analyser.frequencyBinCount/2);
    // let data = new Uint8Array(this.audioService.analyser.frequencyBinCount);
    this.analyser.fftSize = ( meterNum * size * (32 / size));

    // const data = new Float32Array(this.audioService.analyser.fftSize);
    const data = new Uint8Array(this.analyser.fftSize);
    this.analyser.minDecibels = -120;
    this.analyser.maxDecibels = -20;
    this.analyser.smoothingTimeConstant = 0.55;
    // this.audioService.analyser.getFloatFrequencyData(data);
    this.analyser.getByteFrequencyData(data);
    // console.log(data);
    return this.mergeEachValues(data, this.analyser.fftSize / size);
    // return data;
  }

  mergeEachValues(array: Uint8Array, spctParts) {
    const partsMergedNb = Math.ceil(array.length / spctParts);
    let i = 0;
    let mergedArray = [];
    for (i; i < array.length; i += partsMergedNb) {
      let value = 0;
      for (let j = 0; j < partsMergedNb; j++) {
        const tmp = array[i + j] * this.gain;
        value += (isNaN(tmp)) ? 0 : tmp;
      }
      mergedArray.push(value);
    }

    return mergedArray;
  }

  get3DFreqData(spctParts, merge = true)
  {
    // let data = new Float32Array(this.audioService.analyser.frequencyBinCount/2);
    // let data = new Uint8Array(this.audioService.analyser.frequencyBinCount);

    this.analyser3D.fftSize = 1024;

    // const data = new Float32Array(this.audioService.analyser.fftSize);
    const data = new Uint8Array(this.analyser3D.fftSize);
    this.analyser.minDecibels = -120;
    this.analyser.maxDecibels = -20;
    // this.analyser3D.smoothingTimeConstant = 0.15;
    // this.audioService.analyser.getFloatFrequencyData(data);
    this.analyser3D.getByteFrequencyData(data);
    // this.analyser3D.getByteTimeDomainData(data);
    if (merge) {
      return this.mergeEachValues(data, spctParts);
    }
    return data;
  }
}
