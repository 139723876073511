<!--<div class="sidebar side-buttons-container">-->
    <div class="content box" [ngClass]="(isclosed) ? 'ng-hide-remove' : 'ng-hide-add'">
        <ng-content select=".sidebar-body"></ng-content>
    </div>
<div class="button toggle">
    <a (click)="toggle(); false" href="" [hidden]="closable && isclosed">hide sidebar</a>
    <a (click)="toggle(); false" href="" [hidden]="closable && !isclosed">show sidebar</a>
    </div>
<!--</div>-->
<!--<mat-drawer-container class="example-container" autosize>-->

