<!--<script #vertexShader type="x-shader/x-vertex">-->
<!--	attribute float size;-->
<!--			varying vec3 vColor;-->
<!--			void main() {-->
<!--				vColor = color;-->
<!--				vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );-->
<!--				gl_PointSize = size * ( 300.0 / -mvPosition.z );-->
<!--				gl_Position = projectionMatrix * mvPosition;-->
<!--			}-->
<!--</script>-->

<!--<script #fragmentShader type="x-shader/x-fragment">-->
<!--	uniform sampler2D texture;-->
<!--    varying vec3 vColor;-->
<!--    void main() {-->
<!--      gl_FragColor = vec4( vColor, 1.0 );-->
<!--      gl_FragColor = gl_FragColor * texture2D( texture, gl_PointCoord );-->
<!--    }-->
<!--</script>-->
<div #audioViz class="audioViz" style="height:100%;width:100%"></div>
<div [hidden]="hideUi" class="sidebar left-lower side-buttons-container">
  <div folder title="Beat detection">
    <div class="folder-body slider">
      <div class="title">Beat detection treshold: {{ this.gain }}</div>
      <div class="input">
        <input #slider [(ngModel)]="this.gain" (change)="changeGain(slider.value)" type="range" min="0.1" max="5" step="0.1">
      </div>
    </div>
  </div>
</div>

<!--(change)="changeGain(slider.value)"-->
