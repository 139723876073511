

<div #controls class="controls">

  <button class="player-body" mat-button (click)="sidenavleft.toggle()"><mat-icon>menu</mat-icon></button>
  <button mat-icon-button [attr.value]="playerService.isRepeat()" data-id="repeat" class="flat-button repeat rt-sh" (click)="repeat()" title="Répéter">
    <mat-icon>repeat</mat-icon>
  </button>
  <button mat-icon-button [name]="'fast-backward'" (click)="rewind()" data-id="rewind" title="Titre précédent">
    <mat-icon>skip_previous</mat-icon>
  </button>
<!--  <button mat-mini-fab data-id="play" (click)="playerService.toggleSound();" *ngIf="!playerService.isPlaying" title="Lire">-->
<!--    <mat-icon></mat-icon>-->
<!--  </button>-->
  <button style="background-color:white" mat-mini-fab data-id="play" (click)="playerService.toggleSound();" title="Lecture/Pause">
    <mat-icon>{{(playerService.isPlaying) ? 'pause': 'play_arrow' }}</mat-icon>
  </button>
  <!--  {{ isPlaying }}-->
  <!--  <button fa data-id="play" (click)="toggleSound();" class="flat-button ctrl play-pause" title="Lire">-->
  <!--    <fa-icon [icon]="(this.playerService.sound !== undefined) ? (this.playerService.sound.paused) ? faPlay : faPause : faPlay"></fa-icon>-->
  <!--  </button>-->
  <button mat-icon-button [name]="'fast-forward'" (click)="forward()" data-id="forward" title="Titre suivant">
    <mat-icon>skip_next</mat-icon>
  </button>
  <button mat-icon-button [name]="'random'" [attr.value]="playerService.shuffleValue" data-id="shuffle" (click)="shuffle()" class="flat-button shuffle rt-sh" title="Lecture aléatoire">
    <mat-icon>shuffle</mat-icon>
  </button>
  <button mat-icon-button [name]="'mute'" [attr.value]="(playerService.sound !== undefined) ? playerService.sound.muted : true" data-id="shuffle" (click)="mute()" class="flat-button rt-sh" title="Muet">
    <mat-icon>{{(playerService.sound !== undefined) ? (playerService.sound.muted) ? 'volume_off' : 'volume_up' : 'volume_off'}}</mat-icon>
  </button>

  <button mat-icon-button [matMenuTriggerFor]="menu">Menu</button>
  <mat-menu #menu="matMenu">
    <a [routerLink]="['', { outlets: { visualization: ['visualizer', route.path] } }]" mat-menu-item *ngFor="let route of routes; index as i;">{{route.path}}</a>
  </mat-menu>

  <button class="player-body" mat-icon-button (click)="sidenavright.toggle()"><mat-icon>list</mat-icon></button>
</div>

<div class="player_top">
  <span class="time_duration">{{this.getHumanTime(this.playerService.sound?.currentTime)}}</span>
  <div id="time_wrapper">
    <div id="time" (click)="this.updateProgress($event)" [attr.aria-valuenow]="ariavaluenow" [attr.aria-valuemin]="ariavaluemin" [attr.aria-valuemax]="ariavaluemax" >
      <div class="playing-progress-background">
        <div id="progress"></div>
      </div>
    </div>
  </div>
  <span class="time_duration">{{this.getHumanTime(this.playerService.sound?.duration)}}</span>
</div>
<!--<ng-content select=".player-body"></ng-content>-->
