<!--The whole content below can be removed with the new code.-->
<mat-sidenav-container>
  <mat-sidenav mode="side" class="sidebar-left" #sidenavleft autosize>
    <mat-toolbar>
      <mat-toolbar-row class="mainTitle" fxLayoutAlign="center center">
        <h2>BrokenViz</h2>
      </mat-toolbar-row>
      <mat-toolbar-row class="mainTitle" fxLayoutAlign="center center">
        <div *ngFor="let track of tracks"
             [hidden]="track.id != playerService.currentTrack"
             [ngClass]="{'active': track.id == playerService.currentTrack}" >
          <span class="song-title">{{ track.title }}</span>
        </div>
      </mat-toolbar-row>
<!--      <mat-toolbar-row>-->
<!--        <ul class="navigation">-->
<!--          <li>-->
<!--            <a [routerLink]="prev()" class="blue-hover" title="previous animation">-->
<!--              <fa-icon [icon]="faLeft"></fa-icon>-->
<!--            </a>-->
<!--          </li>-->
<!--          <li class="current-anim">-->
<!--            <a href (click)="showDropdown = !showDropdown; false" class="blue-hover">-->
<!--              Current animation:-->
<!--              <span class="animName">{{ currentRoute?.path }}</span>-->
<!--              ({{ (currentIndex) ? currentIndex + 1 : 1}} / {{ routes.length }})-->
<!--            </a>-->

<!--            <ul class="dropdown links width-box" [ngClass]="(showDropdown) ? 'ng-hide-remove' : 'ng-hide-add'">-->
<!--              <ng-container *ngFor="let route of routes"><li *ngIf="route.path" class="blue-hover">-->
<!--&lt;!&ndash;                [routerLink]="['', { outlets: { visualization: ['visualizer'] } }]"&ndash;&gt;-->
<!--&lt;!&ndash;                <a class="slide-right-animation" (click)="showDropdown = !showDropdown; false" routerLink="/visualizer/{{route.path}}" routerLinkActive="active">&ndash;&gt;-->
<!--                <a class="slide-right-animation" (click)="showDropdown = !showDropdown; false" [routerLink]="['', { outlets: { visualization: ['visualizer', route.path] } }]" routerLinkActive="active">-->
<!--                  {{route.path}}-->
<!--                </a>-->
<!--              </li></ng-container>-->
<!--            </ul>-->

<!--          </li>-->
<!--          <li>-->
<!--            <a [routerLink]="next()" class="blue-hover" title="previous animation">-->
<!--              <fa-icon [icon]="faRight"></fa-icon>-->
<!--            </a>-->
<!--          </li>-->

<!--        </ul>-->
<!--      </mat-toolbar-row>-->
    </mat-toolbar>
  </mat-sidenav>

  <mat-sidenav-content class="page-wrap" (click)=handleClicks()>
    <main class="content">
      <div [@routeAnimations]="prepareRoute(outlet)">
        <router-outlet #outlet="outlet" name="visualization"></router-outlet>
      </div>
    </main>
  </mat-sidenav-content>

  <mat-sidenav mode="side" #sidenav position="end" sidebar class="sidebar-right right-lower side-buttons-container" autosize>
    <div class="sidebar-body">
      <div folder title="Spectrum">
        <div class="folder-body">
          <div spectrum data="audioService.spectrum();"></div>
        </div>
      </div>
      <div folder title="Playlist" class="playlist">
        <div class="playlist folder-body">
          <div class="playlist-body">
            <li *ngFor="let track of tracks"
                (click)="play(track.id)"
                [ngClass]="{'active': track.id == playerService.currentTrack}" >
              <span class="song-title">{{ tracks.indexOf(track)+1 }}. {{ track.title }}</span>
            </li>
          </div>
        </div>
      </div>
    </div>

  </mat-sidenav>
  <!--</div>-->
</mat-sidenav-container>


<mat-toolbar [hidden]="hideUi" class="footer">
  <div player [sidenavleft]="sidenavleft" [sidenavright]="sidenav">
  </div>
</mat-toolbar>
