// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  oauth: {
      client: 'e52a332f3fd8a295ad451779f89d9645',
      secret: '6022bbdcb97a68b0908949c83e7a3dc0'
  }
};
