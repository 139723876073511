import {Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, NO_ERRORS_SCHEMA, OnInit, ViewChild} from '@angular/core';
import {slideInAnimation} from '../animations';
import {faArrowLeft, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {NavigationStart, Route, Router, RouterOutlet, Routes} from '@angular/router';
import {PlayerService} from '../services/player.service';
import {PlaylistService} from '../services/playlist.service';
import {AudioService} from '../services/audio.service';
import {ConfigService} from '../services/config.service';
import {routes, routing} from './visualizer.routing';

@Component({
  selector: 'app-visualizer',
  templateUrl: './visualizer.component.html',
  styleUrls: ['./visualizer.component.scss'],
  animations: [
    slideInAnimation
    // animation triggers go here
  ]
})
export class VisualizerComponent implements OnInit {
  @ViewChild('topbar', {static: true}) topbar: ElementRef;
  private playlistID = 25694395;
  faLeft = faArrowLeft;
  faRight = faArrowRight;
  tracks;
  currentRoute: Route;
  currentIndex = 0;
  showDropdown = true;
  playerService: PlayerService;
  public hideUi = false;
  public routes = Object.assign([], routes);
  private clickTimeout = null;
  private isFullScreen = false;

  constructor(public playlistService: PlaylistService, public audioService: AudioService, public router: Router,
              playerService: PlayerService, public configService: ConfigService) {
    this.playerService = playerService;
    this.router = router;
    this.routes.shift();
  }

  ngOnInit() {

    this.router.events.subscribe( (event) => {
      if (event instanceof NavigationStart) {
        this.routes.forEach((route, key) => {
          if ('/visualizer/' + route.path === event.url ) {
            // console.log(this.router.config[route].path);
            this.currentRoute = route;
            this.currentIndex = key;
          }
        });
        if (this.configService.isConfigured() && !this.playerService.sound) {
          window.addEventListener('keydown', (evt) => {
            if (evt.key === 'h') {
              this.hideUi = !this.hideUi;
            }
          });
          this.playlistService.load(this.playlistID);
          this.playlistService.playlistLoaded.subscribe( (data) => {
            this.tracks = this.playlistService.list;
          });
        }
      }
    });
  }

  public play(index) {
    this.playerService.playAudio(index);
  }

  prev() {
    let prevIndex = this.currentIndex - 1;
    if (this.currentIndex === 0) {
      prevIndex = this.routes.length - 1;
    }
    return '/visualizer/' + this.routes[prevIndex].path;
  }

  next() {
    let nextIndex = this.currentIndex + 1;
    if (this.currentIndex === (this.routes.length - 1)) {
      nextIndex = 0;
    }
    return '/visualizer/' + this.routes[nextIndex].path;
  }

  log(val) {
    console.log(val);
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  public handleClicks(): void {
    if (this.clickTimeout) {
      clearTimeout(this.clickTimeout);
      this.toggleFullscreen();
      this.clickTimeout = null;
    } else {
      this.setClickTimeout(() =>
        this.handleSingleClick());
    }
  }

  public setClickTimeout(callback) {
    // clear any existing timeout
    clearTimeout(this.clickTimeout);
    this.clickTimeout = setTimeout(() => {
      this.clickTimeout = null;
      callback();
    }, 200);
  }

  public handleSingleClick() {
    this.playerService.toggleSound();
  }

  toggleFullscreen() {
    let methodToBeInvoked = null;
    let elem = null;
    if (!document.fullscreen) {
      elem = document.documentElement;
      methodToBeInvoked = elem.requestFullscreen
        || elem.webkitRequestFullScreen
        || elem.documentElement['mozRequestFullscreen']
        || elem.documentElement['msRequestFullscreen'];
      this.isFullScreen = true;
    } else {
      elem = document;
      methodToBeInvoked = elem.exitFullscreen
        || elem.webkitExitFullscreen
        || elem['mozCancelFullScreen']
        || elem['msExitFullscreen'];
    }
    methodToBeInvoked.call(elem);
  }
}
