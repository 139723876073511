import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HopalongComponent} from './components/visualisations/hopalong.component';
import {CloudComponent} from './components/visualisations/cloud.component';
import {HedgehogComponent} from './components/visualisations/hedgehog.component';
import {LotusComponent} from './components/visualisations/lotus.component';
import {AngelsComponent} from './components/visualisations/angels.component';
import {AppComponent} from './components/app.component';
import {HomeComponent} from './home/home.component';
import {VisualizerComponent} from './visualizer/visualizer.component';
import {routes} from './visualizer/visualizer.routing';
import {VisualizerModule} from './visualizer/visualizer.module';


@NgModule({
    imports: [
        RouterModule.forRoot([
            { path: 'visualizer',
              // children: routes
              loadChildren: () => VisualizerModule,
              outlet: 'visualization'
            },
            // { path: '',   redirectTo: 'hopalong', pathMatch: 'full'},
            // { path: 'visualizer', component: HopalongComponent, data : {animation: 'hopalong'} },
            // { path: 'cloud', component: CloudComponent, data : {animation: 'cloud'} },
            // // { path: 'particles', component: ParticlesComponent },
            // { path: 'hedgehog', component: HedgehogComponent, data : {animation: 'hedgehog'} },
            // { path: 'lotus', component: LotusComponent, data : {animation: 'lotus'} },
            // { path: 'angels', component: AngelsComponent, data : {animation: 'angels'} },
            // { path: '**', component: PageNotFoundComponent }
            { path: '',   component: HomeComponent, pathMatch: 'full'},
          ],
          { enableTracing: false, onSameUrlNavigation: 'reload' })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
}
