import {DBConfig} from 'ngx-indexed-db';

export const dbConfig: DBConfig  = {
  name: 'BrokenViz',
  version: 1,
  objectStoresMeta: [{
    store: 'config',
    storeConfig: { keyPath: 'id', autoIncrement: false },
    storeSchema: [
      { name: 'random', keypath: 'random', options: { unique: true } },
      { name: 'repeat', keypath: 'repeat', options: { unique: true } },
      { name: 'song', keypath: 'song', options: { unique: true } }
    ]
  }]
};
