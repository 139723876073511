import {ViewChild, ElementRef, OnInit, OnDestroy, HostListener, Renderer2, AfterViewInit, Directive} from '@angular/core';
import Stats from 'stats.js';

import {AudioService} from '../../services/audio.service';
import {Router} from '@angular/router';
import {PlayerService} from '../../services/player.service';
import {PlaylistService} from '../../services/playlist.service';

import * as THREE from 'three';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import {debounceTime} from 'rxjs/operators';
import {fromEvent, merge} from 'rxjs';
declare let THREEx: any;

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class AbstractWebglViewComponent implements OnDestroy, OnInit {

  @ViewChild('audioViz', {static: true}) elementRef: ElementRef;
  protected camera: THREE.PerspectiveCamera;
  protected renderer: THREE.WebGLRenderer;
  protected stats: any;
  protected composer: EffectComposer;
  protected scene: THREE.Scene;
  protected element: any;
  protected frame: any;
  protected windowHalfX;
  protected windowHalfY;
  protected running = false;
  hideUi = false;
  private el: any;
  protected frameCount: any = 0;

  constructor(
    public audioService: AudioService,
    public router: Router,
    public renderer2: Renderer2,
    public playerService: PlayerService,
    public playlistService: PlaylistService
  ) {
    this.renderer2 = renderer2;
    this.router = router;
  }


  ngOnInit(): void {
    window.addEventListener('keydown', (event) => {
      if (event.key === 'h') {
        if (!this.hideUi) {
          this.renderer2.removeChild(this.element, this.stats.domElement);
        } else {
          this.renderer2.appendChild(this.element, this.stats.domElement);
        }
        this.hideUi = !this.hideUi;
      }
    });
    // console.log('webgl init');
    // console.log(this.audioService.isInit);
    if (!this.running && this.audioService.isInit) {
      // console.log('set running to true');
      this.running = true;
      this.draw();
      this.onWindowResize();
    }
    this.audioService.audioReady.subscribe(() => {
      // console.log('music ready');
      this.running = true;
      this.draw();
      this.onWindowResize();
    });
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    this.running = false;
    this.renderer = null;
    cancelAnimationFrame(this.frame);
    this.frame = null;
  }

  animationFrame() {
    if (!this.running) { return false; }
    const clock = new THREE.Clock();
    // do the render
    const d = clock.getDelta() * 1000;
    this.render(this.renderer, d);
    // update stats
    if (this.stats) {
      this.stats.update();
    }
    this.frame = requestAnimationFrame(() => this.animationFrame());

  }

  draw() {
    this.element = this.elementRef.nativeElement;
    const width = this.element.offsetWidth;
    const height = this.element.offsetHeight;
    this.renderer = new THREE.WebGLRenderer({
      powerPreference: 'high-performance',
      antialias: false,
      preserveDrawingBuffer: false,
      stencil: false,
      depth: false
    });

    this.renderer.setSize(width, height);
    this.renderer.domElement.style.height = '100%';
    this.renderer.domElement.style.width = '100%';
    this.renderer.domElement.style.position = 'absolute';
    this.renderer2.appendChild(this.element, this.renderer.domElement);

    // add Stats.js - https://github.com/mrdoob/stats.js
    this.stats = new Stats();
    this.stats.domElement.style.position = 'absolute';
    this.stats.domElement.style.top  = '30px';
    this.stats.domElement.style.left = '0px';
    this.renderer2.appendChild(this.element, this.stats.domElement);

    // allow 'p' to make screenshot
    // THREEx.Screenshot.bindKey(this.renderer);
    // // allow 'f' to go fullscreen where this feature is supported
    // if ( THREEx.FullScreen.available() ) {
    //   THREEx.FullScreen.bindKey({
    //     dblclick	: true
    //   });
    // }
    this.renderer2.appendChild(this.element, this.renderer.domElement);
    this.scene_init(this.renderer);

    this.animationFrame();
  }

  consolelog(...args: any[]) {
    this.frameCount ++;
    if (this.frameCount === 300 ) {
      args.forEach(function(element) {
        console.log(element);
      });
      this.frameCount = 0;
    }
  }

  onWindowResize = () => {
    this.windowHalfX = window.innerWidth / 2;
    this.windowHalfY = window.innerHeight / 2;
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    // if (this.renderer) {
    //   this.renderer.setSize(window.innerWidth, window.innerHeight);
    // }
  }

  abstract scene_init (renderer);

  abstract render(renderer, time_delta);
}
