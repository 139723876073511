import {Component, ElementRef, Input, NgZone, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import { PlaylistService } from '../services/playlist.service';
import { AudioService } from '../services/audio.service';
import {PlayerService} from '../services/player.service';
import {
  faPause,
  faPlay,
  faRandom,
  faFastForward,
  faFastBackward,
  faRedoAlt,
  faVolumeMute,
  faVolumeUp
} from '@fortawesome/free-solid-svg-icons';
import {NgxIndexedDBService} from 'ngx-indexed-db';
import {fromEvent} from 'rxjs';
import {MatSidenav} from '@angular/material/sidenav';
import {routes, routing} from '../visualizer/visualizer.routing';

@Component({
  selector: '[player]',
  templateUrl: '../views/player.component.html',
  styleUrls: []
})

export class PlayerComponent implements OnInit {
  @ViewChild('controls', {static: true}) controls: ElementRef;
  @Input() public sidenavleft: MatSidenav;
  @Input() public sidenavright: MatSidenav;
  faPlay = faPlay;
  faRandom = faRandom;
  faPause = faPause;
  faFastForward = faFastForward;
  faFastBackward = faFastBackward;
  faRedoAlt = faRedoAlt;
  faVolumeMute = faVolumeMute;
  faVolumeUp = faVolumeUp;
  ariavaluemin;
  ariavaluemax;
  ariavaluenow;
  onProgress = false;
  time;
  public loading: any = false;
  dbService: NgxIndexedDBService;

  public routes = Object.assign([], routes);
  constructor(public playlistService: PlaylistService, public audioService: AudioService, public el: ElementRef,
              private _renderer: Renderer2, public playerService: PlayerService, dbService: NgxIndexedDBService, private ngZone: NgZone) {
    this.routes.shift();
    this.dbService = dbService;
    dbService.getByKey('config', 1).subscribe(
        (config: any) => {
        if (config) {
          console.log(config);
          playerService.shuffleValue = config.random;
        }
      },
      reason => {
        dbService.add('config', { random: playerService.shuffleValue, id: 1});
      }
    );
    dbService.getByKey('config', 2).subscribe(
        (config: any) => {
        if (config) {
          playerService.isRepeat(config.repeat);
        }
      },
      reason => {
        playerService.isRepeat('NO_REPEAT');
        dbService.add('config', { repeat: playerService.isRepeat(), id: 2});
      }
    );
  }

  ngOnInit() {
    this.playlistService.playlistLoaded.subscribe((data) => {
      if (this.playerService.isShuffled()) {
        this.playAudio(this.playlistService.list[Math.floor(Math.random() * this.playlistService.list.length)].id);
      } else {
        this.playAudio(this.playlistService.list[0].id);
      }
    });
    this.audioService.audioReady.subscribe(() => {

      const spaceEvent  = fromEvent<KeyboardEvent>(document, 'keyup');

      spaceEvent.subscribe((event) => {
        if (event.code === 'Space') {
          this.playerService.toggleSound();
          event.stopPropagation();
          event.preventDefault();
          return;
        }
      });
      this.playerService.sound.addEventListener('playing', () => {
        this.ngZone.run( () => {
          this.playerService.isPlaying = true;
          // console.log('isPlaying : ', this.isPlaying);
        });
      });

      this.playerService.sound.addEventListener('pause', () => {
        this.ngZone.run( () => {
          this.playerService.isPlaying = false;
          // console.log('isPlaying : ', this.isPlaying);
        });
      });
      const controls = this.el.nativeElement.querySelector('.controls');
      this.time = {
        background: this.el.nativeElement.querySelector('#time'),
        progress: this.el.nativeElement.querySelector('#progress')
      };
      // this.time.background.on('click', (event) => { this.updateProgress(event) })
      this.playerService.sound.ontimeupdate = () => {
        this.progress();
      };
    });
  }

  public progress() {
    this.onProgress = true;
    this.setTime(false);
    this.ariavaluenow = this.playerService.sound.currentTime;
    this.updateProgressBar();
    // this.el.nativeElement.querySelector('.track_time').html(this.sec2min(this.playerService.sound.currentTime)
    //     + ' / ' + this.sec2min(this.playerService.sound.duration));
  }

  setTime(val) {
    this.ariavaluemin = 0;
    this.ariavaluemax = this.playerService.sound.duration;
    if (val) {
      this.ariavaluenow = val;
    } else {
      this.ariavaluenow = 0;
    }
  }

  updateProgress(event) {
    this.onProgress = false;
    const x = event.layerX;
    const w = this.time.background.offsetWidth;
    const percent = x * 100 / w;
    const total = this.playerService.sound.duration;
    const progress = total * percent / 100;
    this.playerService.sound.currentTime = progress;
    this.setTime(this.playerService.sound.currentTime);
    this.updateProgressBar();
  }

  rewind() {
    if (this.playerService.repeatOne) {
      this.playerService.sound.currentTime = 0;
      this.playerService.play();
    } else if (this.playerService.isShuffled() && !this.playerService.repeatOne) {
      this.playerService.nextShuffled--;
      if (this.playerService.nextShuffled < 0) {
        this.playerService.nextShuffled = this.playlistService.listShuffled.length;
      }
      this.playAudio(this.playlistService.listShuffled[this.playerService.nextShuffled].id);
    } else {
      this.playerService.next--;
      if (this.playerService.next < 0) {
        this.playerService.next = this.playlistService.list.length - 1;
      }
      this.playAudio(this.playlistService.list[this.playerService.next].id);
    }
  }

  updateProgressBar() {
    this.time.progress.style.width = (100 * this.playerService.sound.currentTime) / this.ariavaluemax + '%';
  }

  forward() {
    if (this.playerService.repeatOne) {
      this.playerService.sound.currentTime = 0;
      this.playerService.play();
    } else if (this.playerService.isShuffled() && !this.playerService.repeatOne) {
      this.playerService.nextShuffled++;
      if (this.playerService.nextShuffled >= this.playlistService.listShuffled.length) {
        this.playerService.nextShuffled = 0;
      }
      this.playAudio(this.playlistService.listShuffled[this.playerService.nextShuffled].id);
    } else {
      this.playerService.next++;
      if (this.playerService.next >= this.playlistService.list.length) {
        this.playerService.next = 0;
      }
      this.playAudio(this.playlistService.list[this.playerService.next].id);
    }
  }

  shuffle() {
    if (this.playerService.shuffleValue === 'NO_SHUFFLE') {
      this.playerService.shuffleValue =  'ALL_SHUFFLE';
      this.dbService.update('config', {random: 'ALL_SHUFFLE', id: 1});
    } else {
      this.playerService.shuffleValue = 'NO_SHUFFLE';
      this.dbService.update('config', {random: 'NO_SHUFFLE', id: 1});
    }
  }

  mute(val?: boolean) {
    if (val === undefined) {
      this.playerService.sound.muted = !this.playerService.sound.muted;
    } else {
      this.playerService.sound.muted = val;
    }
  }

  repeat() {
    if (this.playerService.isRepeat() === 'NO_REPEAT') {
      this.playerService.repeatOne = true;
      this.dbService.update('config', {repeat: 'SINGLE_REPEAT', id: 2});
    } else {
      this.dbService.update('config', {repeat: 'NO_REPEAT', id: 2});
      this.playerService.repeatOne = false;
    }
  }

  getHumanTime(seconds: number): string
  {
    let minutes: any = Math.floor(seconds / 60);
    let secs: any = Math.floor(seconds % 60);
    if (isNaN(minutes)) {
      minutes = 0;
    }
    if (isNaN(secs)) {
      secs = 0;
    }

    if (minutes < 10) {
      minutes = '0' + minutes;
    }

    if (secs < 10) {
      secs = '0' + secs;
    }

    return minutes +  ':' + secs;
  }

  public playAudio(track_index) {
    if (!this.loading) {
      const sound = this.playerService.playAudio(track_index).then((audio: HTMLAudioElement) => {
        audio.onended = () => {
              this.forward();
            };
      });
    }
  }

  public nextTrack() {
    this.playAudio((this.playerService.currentIndex + 1 ) % this.playlistService.tracks.length);
  }


}
