import {Component} from '@angular/core';
import { AbstractWebglViewComponent } from './AbstractWebglView.abstract';
import * as THREE from 'three';
import { BloomPass } from 'three/examples/jsm/postprocessing/BloomPass.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import {ShaderPass} from 'three/examples/jsm/postprocessing/ShaderPass';
import {UnrealBloomPass} from 'three/examples/jsm/postprocessing/UnrealBloomPass';
declare let THREEx: any;

@Component({
  templateUrl: '../../views/visualisations/cloud.component.html',
})
export class CloudComponent extends AbstractWebglViewComponent {
  // https://codepen.io/seanseansean/pen/EaBZEY
  scene: THREE.Scene;
  camera: THREE.PerspectiveCamera;
  geometry;
  particles;
  materials = [];
  cloudNb = 10;
  particleSize = 15;
  particleCount = 2500;
  public gain = 2;
  protected shaderExtras = {
    screen: {
      uniforms: {
        tDiffuse: {type: 't', value: 1, texture: { value: new THREE.TextureLoader().load( 'assets/sprite/spark.png' ) }},
        opacity: {type: 'f', value: 1.0}
      },
      vertexShader: [
        'varying vec2 vUv;',
        'void main() {',
          'vUv = vec2( uv.x, 1.0 - uv.y );',
          'gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );',
        '}'
      ].join('\n'),
      fragmentShader: [
        'uniform float opacity;',
        'uniform sampler2D tDiffuse;',
        'varying vec2 vUv;',
        'void main() {',
          'vec4 texel = texture2D( tDiffuse, vUv );',
          'gl_FragColor = opacity * texel;',
        '}'
      ].join('\n')
    }
  };

  changeGain(val: number) {
    this.gain = val;
  }

  scene_init (renderer) {
    const HEIGHT = window.innerHeight;
    const WIDTH = window.innerWidth;
    this.windowHalfX = WIDTH / 2;
    this.windowHalfY = HEIGHT / 2;
    const fieldOfView = 75;
    const aspectRatio = WIDTH / HEIGHT;
    const nearPlane = 10;
    const farPlane = 3000;

    const cameraZ = farPlane / 8  ; /*	So, 1000? Yes! move on!	*/
    const fogHex = 0x000000; /* As black as your heart.	*/
    const fogDensity = 0.0002; /* So not terribly dense?	*/
    this.camera = new THREE.PerspectiveCamera(fieldOfView, aspectRatio, nearPlane, farPlane);
    this.camera.position.z = cameraZ;
    this.scene = new THREE.Scene();
    this.scene.fog = new THREE.FogExp2(fogHex, fogDensity);
    this.camera.lookAt(this.scene.position);
    this.geometry = new THREE.BufferGeometry(); /*	NO ONE SAID ANYTHING ABOUT MATH! UGH!	*/
    THREEx.WindowResize.bind(renderer, this.camera);
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1.8);
    directionalLight.position.set(0, -1, 1);
    directionalLight.position.normalize();
    this.scene.add(directionalLight);
    const radius = 200;
    /*	Hope you took your motion sickness pills; We're about to get loopy.	*/
    let positions = [];
    for ( let i = 0; i < this.particleCount; i ++ ) {
      positions.push( ( Math.random() * 10 - 5 ) * radius );
      positions.push( ( Math.random() * 10 - 5 ) * radius );
      positions.push( ( Math.random() * 10 - 5 ) * radius );
    }
    const vertices = new Float32Array( positions );
    // this.geometry.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );
    this.geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( positions, 3 ) );

    /*	We can't stop here, this is bat country!	*/
    /*	I told you to take those motion sickness pills.
      Clean that vommit up, we're going again!	*/
    for ( let i = 0; i < this.cloudNb; i++) {
      const color = new THREE.Color();
      color.setHSL( i / this.cloudNb, 1.0, 0.5 );
      this.materials[i] = new THREE.PointsMaterial({
        size: this.particleSize,
        color: color,
        map: new THREE.TextureLoader().load( 'assets/sprite/spark.png' ),
        blending: THREE.AdditiveBlending,
        alphaTest: 0.5,
        sizeAttenuation: false
      });

      this.particles = new THREE.Points(this.geometry, this.materials[i]);
      this.particles.rotation.x = Math.random() * 6;
      this.particles.rotation.y = Math.random() * 6;
      this.particles.rotation.z = Math.random() * 6;
      this.scene.add(this.particles);

      this.composer = new EffectComposer(renderer);
      this.composer.setSize(window.innerWidth, window.innerHeight);
      renderer.autoClear = false;

      const renderModel = new RenderPass(this.scene, this.camera);
      this.composer.addPass(renderModel);

      // const effectBloom = new BloomPass(3);
      // this.composer.addPass(effectBloom);

      const bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 3, 1, 0 );
      this.composer.addPass(bloomPass);


      const effectScreen = new ShaderPass(this.shaderExtras[ 'screen' ]);
      effectScreen.renderToScreen = true;
      this.composer.addPass(effectScreen);

      window.addEventListener('resize', this.onWindowResize, false);
    }
    /*	If my calculations are correct, when this baby hits 88 miles per hour...
      you're gonna see some serious shit.	*/

  }

  render(renderer, time_delta) {
    this.camera.lookAt(this.scene.position);
    const data = this.audioService.get3DFreqData(this.scene.children.length * 5);
    // console.log(data);
    for (let i = 0; i < this.scene.children.length; i++) {
      const object = this.scene.children[i];
      if (object instanceof THREE.Points) {
        const ratioTime = (data[i] / 1000000) * this.gain;
        object.rotation.y += ratioTime;
        object.rotation.z += ratioTime;
        object.rotation.x += ratioTime;
      }
    }
    // renderer.clear();
    renderer.render( this.scene, this.camera );
    // this.composer.render();
  }
}
