import { BrowserModule, Title } from '@angular/platform-browser';
import {NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA, APP_BOOTSTRAP_LISTENER, ComponentRef} from '@angular/core';
import { AppComponent } from './components/app.component';
import { FolderComponent } from './components/folder.component';
import { SidebarComponent } from './components/sidebar.component';
import { SpectrumComponent } from './components/spectrum.component';
import { PlaylistService } from './services/playlist.service';
import { AudioService } from './services/audio.service';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HedgehogComponent } from './components/visualisations/hedgehog.component';
import { AppRoutingModule } from './appRouting.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { PlayerComponent } from './components/player.component';
import { PlayerService } from './services/player.service';
import {APP_BASE_HREF} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {NgxIndexedDBModule, NgxIndexedDBService} from 'ngx-indexed-db';
import {dbConfig} from './db';
import {VisualizerComponent} from './visualizer/visualizer.component';
import {HomeComponent} from './home/home.component';
import {ConfigService} from './services/config.service';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatMenuModule} from '@angular/material/menu';
import {MatToolbarModule} from '@angular/material/toolbar';
import {AuthInterceptor} from './interceptors/auth.interceptor';

const PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    declarations: [
      AppComponent,
      VisualizerComponent,
      HomeComponent,
      FolderComponent,
      SidebarComponent,
      SpectrumComponent,
      PlayerComponent,
    ],
  imports: [
    FontAwesomeModule,
    PerfectScrollbarModule,
    FormsModule,
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    FlexLayoutModule,
    MatButtonModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule
  ],
    bootstrap: [
      AppComponent
    ],
    exports: [
    ],
    providers: [
      PlaylistService,
      AudioService,
      PlayerService,
      ConfigService,
      MatIconRegistry,
      Title,
      {provide: APP_BASE_HREF, useValue : '/' },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
      }
    ],
    schemas:      [ NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {
}
