import {Component} from '@angular/core';
import { AbstractWebglViewComponent } from './AbstractWebglView.abstract';

import * as THREE from 'three';
import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer';
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass';
import {UnrealBloomPass} from 'three/examples/jsm/postprocessing/UnrealBloomPass';

@Component({
  templateUrl: '../../views/visualisations/generic.component.html',
})
export class HopalongComponent extends AbstractWebglViewComponent {
  SCALE_FACTOR = 1500;
  scene: THREE.Scene;
  camera: THREE.PerspectiveCamera;
  A_MIN = -30;
  A_MAX = 30;
  B_MIN = .2;
  B_MAX = 1.8;
  C_MIN = 5;
  C_MAX = 17;
  D_MIN = 0;
  D_MAX = 10;
  E_MIN = 0;
  E_MAX = 12;
  a: any;
  b: any;
  c: any;
  d: any;
  e: any;
  orbit = {
    subsets: [],
    xMin: 0,
    xMax: 0,
    yMin: 0,
    yMax: 0,
    scaleX: 0,
    scaleY: 0
  };
  hueValues = [];
  NUM_POINTS_SUBSET = 16000;
  NUM_SUBSETS = 7;
  NUM_LEVELS = 4;
  DEF_BRIGHTNESS = 1;
  DEF_SATURATION = 0.8;
  LEVEL_DEPTH = 600;
  SPRITE_SIZE = 5;
  // rotationSpeed = 0.005;
  rotationSpeed = 0;
  public gain = 2;

  changeGain(val: number) {
    this.gain = val;
  }

  scene_init (renderer) {
    // const sprite1 = THREE.ImageUtils.loadTexture( 'assets/sprite/galaxy.png' );
    for (let i = 0; i < this.NUM_SUBSETS; i++) {
      const subsetPoints = [];
      for (let j = 0; j < this.NUM_POINTS_SUBSET; j++) {
        subsetPoints[j] = {
          x: 0,
          y: 0,
          vertex: new THREE.Vector3( 0, 0, 0 )
        };
      }
      this.orbit.subsets.push(subsetPoints);
    }
    this.camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 1, 3 * this.SCALE_FACTOR );
    this.camera.position.z = this.SCALE_FACTOR / 2;
    this.scene = new THREE.Scene();
    this.scene.fog = new THREE.FogExp2( 0x000000, 0.0010);

    this.generateOrbit();

    for (let s = 0; s < this.NUM_SUBSETS; s++) {
      this.hueValues[s] = Math.random();
    }
    // Create particle systems
    for (let k = 0; k < this.NUM_LEVELS; k++) {
      for (let s = 0; s < this.NUM_SUBSETS; s++) {
        // const geometry = new THREE.CircleGeometry();
        const points = []
        for (let i = 0; i < this.NUM_POINTS_SUBSET; i++) {
          points.push( this.orbit.subsets[s][i].vertex);
        }
        const geometry = new THREE.BufferGeometry().setFromPoints(points);

        const color = new THREE.Color();
        color.setHSL( this.hueValues[s], this.DEF_SATURATION, this.DEF_BRIGHTNESS );
        color.setHex( Math.random() * 0xffffff );
        const materials = new THREE.PointsMaterial({
          size: (this.SPRITE_SIZE ),
          map: new THREE.TextureLoader().load( 'assets/sprite/galaxy.png' ),
          blending: THREE.AdditiveBlending,
          depthTest: false,
          transparent : true,
          color: color,
          alphaTest: 0.5,
        });
        const particles = new THREE.Points( geometry, materials );
        particles.userData['mySubset'] = s;
        particles.position.x = 0;
        particles.position.y = 0;
        particles.position.z = - this.LEVEL_DEPTH * k - (s  * this.LEVEL_DEPTH / this.NUM_SUBSETS) + this.SCALE_FACTOR / 2;
        particles.userData['needsUpdate'] = 0;
        this.scene.add( particles );

      }
    }


    this.composer = new EffectComposer(renderer);
    this.composer.setSize(window.innerWidth, window.innerHeight);
    renderer.autoClear = false;
    const renderModel = new RenderPass(this.scene, this.camera);
    this.composer.addPass(renderModel);

    const bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1, 0, 0 );
    this.composer.addPass(bloomPass);

    setInterval(() => { this.updateOrbit(); }, 3000);
    window.addEventListener('resize', this.onWindowResize, false);
  }

  render(renderer, time_delta) {

    const data = this.audioService.get3DFreqData(this.scene.children.length * 5);

    this.camera.lookAt( this.scene.position );
    this.scene.children.forEach((child: any, i) => {
      const ratioTime = (data[i] / 100) * this.gain;
      child.position.z +=  ratioTime;
      child.rotation.z += this.rotationSpeed;
      if (child.position.z > this.camera.position.z) {
        // L'objet est actuellement en dehors du champ de vision.
        child.position.z = - (this.NUM_LEVELS - 1) * this.LEVEL_DEPTH;
        if (child.userData['needsUpdate'] === 1) {
          child.material.color.setHSL(
            this.hueValues[child.userData['mySubset']],
            this.DEF_SATURATION, this.DEF_BRIGHTNESS
          );

          const geometry = child.geometry as THREE.BufferGeometry;

          const positions = geometry.getAttribute('position') as THREE.BufferAttribute;

          const points = []
          const level = Math.floor(i / this.NUM_SUBSETS);
          for (let s = 0; s < this.NUM_POINTS_SUBSET; s++) {
            points.push(this.orbit.subsets[level][s].vertex);
          }
          geometry.setFromPoints(points);

          child.material.color.setHex(Math.random() * 0xffffff);
          child.userData['needsUpdate'] = 0;
          child.geometry.verticesNeedUpdate = true;
          child.geometry.__dirtyVertices = true;
          child.geometry.attributes.position.needsUpdate = true
        }
      }
    });
    // this.renderer.render( this.scene, this.camera );
    if (this.composer) {
      renderer.clear();
      this.composer.render();
    } else {
      renderer.render( this.scene, this.camera );
    }
  }

  shuffleParams() {
    this.a = this.A_MIN + Math.random() * (this.A_MAX - this.A_MIN);
    this.b = this.B_MIN + Math.random() * (this.B_MAX - this.B_MIN);
    this.c = this.C_MIN + Math.random() * (this.C_MAX - this.C_MIN);
    this.d = this.D_MIN + Math.random() * (this.D_MAX - this.D_MIN);
    this.e = this.E_MIN + Math.random() * (this.E_MAX - this.E_MIN);
  }

  prepareOrbit() {
    this.shuffleParams();
    this.orbit.xMin = 0;
    this.orbit.xMax = 0;
    this.orbit.yMin = 0;
    this.orbit.yMax = 0;
  }

  generateOrbit() {
    let x, y, z, x1;
    let idx = 0;

    this.prepareOrbit();

    // Using local vars should be faster
    const al = this.a;
    const bl = this.b;
    const cl = this.c;
    const dl = this.d;
    const el = this.e;
    const subsets = this.orbit.subsets;

    let xMin = 0, xMax = 0, yMin = 0, yMax = 0;
    let choice;
    choice = Math.random();

    for (let s = 0; s < this.NUM_SUBSETS; s++) {

      // Use a different starting point for each orbit subset
      x = s * .005 * (0.5 - Math.random());
      y = s * .005 * (0.5 - Math.random());
      const curSubset = subsets[s];

      for (let i = 0; i < this.NUM_POINTS_SUBSET; i++) {

        // Iteration formula (generalization of the Barry Martin's original one)

        if (choice < 0.5) {
          z = (dl + (Math.sqrt(Math.abs(bl * x - cl))));
        } else if (choice < 0.75) {
          z = (dl + Math.sqrt(Math.sqrt(Math.abs(bl * x - cl))));

        } else {
          z = (dl + Math.log(2 + Math.sqrt(Math.abs(bl * x - cl))));
        }

        if (x > 0) {
          x1 = y - z;
        } else if (x === 0) {
          x1 = y;
        } else { x1 = y + z; }
        y = al - x;
        x = x1 + el;

        curSubset[i].x = x;
        curSubset[i].y = y;

        if (x < xMin) {
          xMin = x;
        } else if (x > xMax) { xMax = x; }
        if (y < yMin) {
          yMin = y;
        } else if (y > yMax) { yMax = y; }

        idx++;
      }
    }

    const scaleX = 2 * this.SCALE_FACTOR / (xMax - xMin);
    const scaleY = 2 * this.SCALE_FACTOR / (yMax - yMin);

    this.orbit.xMin = xMin;
    this.orbit.xMax = xMax;
    this.orbit.yMin = yMin;
    this.orbit.yMax = yMax;
    this.orbit.scaleX = scaleX;
    this.orbit.scaleY = scaleY;

    // Normalize and update vertex data
    for (let s = 0; s < this.NUM_SUBSETS; s++) {
      const curSubset = subsets[s];
      for (let i = 0; i < this.NUM_POINTS_SUBSET; i++) {
        curSubset[i].vertex.x = scaleX * (curSubset[i].x - xMin) - this.SCALE_FACTOR;
        curSubset[i].vertex.y = scaleY * (curSubset[i].y - yMin) - this.SCALE_FACTOR;
      }
    }
  }

  updateOrbit() {
    // console.log('ici');
    this.generateOrbit();
    this.scene.children.forEach((child: any, index) => {
      child.userData['needsUpdate'] = 1
    })

  }


}
