import { Component, ElementRef, Input} from '@angular/core';
@Component({
    selector: 'sidebar',
    templateUrl: '../views/sidebar.component.html',
})
export class SidebarComponent {
    @Input() closable: string;
    isclosed = false;
    constructor(public el: ElementRef) {}
    toggle() {
        this.isclosed = !this.isclosed;
    }
}
