import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {PlaylistService} from './playlist.service';
import {AudioService} from './audio.service';
import * as _ from 'underscore';
import {AuthService} from './auth.service';
import {take} from 'rxjs/operators';
import {resolve} from '@angular/compiler-cli/src/ngtsc/file_system';
@Injectable()
export class PlayerService {

  public sound: HTMLAudioElement;
  public currentIndex: any;
  playlistService: PlaylistService;
  audioService: AudioService;
  next = 0;
  nextShuffled = 0;
  track = {};
  volume = 100;
  repeatOne = false;
  titleService: Title;
  public currentTrack: any;
  public shuffleValue = 'ALL_SHUFFLE';
  public isPlaying = false;

  constructor(playlistService: PlaylistService, audioService: AudioService, titleService: Title, private authService: AuthService) {
    this.playlistService = playlistService;
    this.audioService = audioService;
    this.titleService = titleService;

  }
  playAudio(id) {
    this.next = this.index(this.playlistService.list, id);
    this.currentIndex = this.currentTrack = id;
    this.nextShuffled = this.index(this.playlistService.listShuffled, id);
    this.track = (this.isShuffled()) ? this.playlistService.listShuffled[this.nextShuffled] : this.playlistService.list[this.next];
    return new Promise( (resolve) => {
      this.authService.get('tracks/' + this.track['id'] + '/streams').pipe(
          take(1) //useful if you need the data once and don't want to manually cancel the subscription again
      ).subscribe(response => {
        if (!this.sound) {
          this.sound = new Audio(response['http_mp3_128_url']);
        } else {
          this.sound.src = response['http_mp3_128_url'];
        }
        this.sound.load();
        this.titleService.setTitle('AudioViz - ' + this.track['title']);
        this.sound.id = this.track['id'];
        this.sound.crossOrigin = 'anonymous';
        this.sound.volume = this.volume / 100;
        this.sound.autoplay = true;
        // this.sound.oncancel = (e) => {
        //   console.log(e);
        // };
        this.sound.oncanplay = (e) => {
          this.audioService.decode(this.sound);
        };
        // console.log(this.sound);
        // this.sound.paused = false;
        resolve(this.sound);
      })

    })

  }

  index(array, key) {
    let Nkey;
    for (const c in array) {
      if (array[c].id === key) {
        Nkey = c;
      }
    }
    return Nkey;
  }

  public spectrum() {
    if (this.audioService.analyser) {
      this.audioService.analyser.fftSize = ( 512 );
      const bufferLength = this.audioService.analyser.frequencyBinCount;
      const data = new Uint8Array(bufferLength);
      this.audioService.analyser.getByteTimeDomainData(data);
      return data;
    } else {
      return [];
    }
  }

  isShuffled(): boolean {
    if (this.shuffleValue === 'ALL_SHUFFLE') {
      return true;
    } else {
      return false;
    }
  }

  isRepeat(value?: string): string|boolean {
    if (value) {
      if (value === 'SINGLE_REPEAT') {
        return this.repeatOne = true;
      } else {
        return this.repeatOne = false;
      }
    } else {
      if (this.repeatOne) {
        return 'SINGLE_REPEAT';
      } else {
        return 'NO_REPEAT';
      }
    }
  }

  public toggleSound() {
    if (this.sound) {
      if (this.sound.paused) {
        this.play();
      } else {
        this.pause();
      }
    }
  }


  public play() {
    this.sound.play();
  }

  public pause() {
    if (this.sound) {
      if (!this.sound.paused) {
        this.sound.pause();
      }
    }
  }

}
