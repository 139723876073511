import { EventEmitter, Injectable, Input, Output } from '@angular/core';
import {HttpClient, HttpContext, HttpEvent, HttpHeaders, HttpParams, HttpRequest, HttpResponse} from '@angular/common/http';
import  { AudioService } from './audio.service';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {TokenService} from './token.service';
@Injectable()
export class PlaylistService {
    @Output() playlistLoaded = new EventEmitter();
    @Input() streamSrc: string;


    public playlist: any;
    public clientID: string = 'e52a332f3fd8a295ad451779f89d9645';
    public list = [];
    public listShuffled = [];
    token: Object
    constructor(private authService: AuthService, private tokenService: TokenService, private audio: AudioService, private http: HttpClient
    ) {
    }

    public load(id) {
        return new Promise( (resolve) => {
                this.authService.get('playlists/' + id).subscribe((data) => {
                    console.log(data);
                    this.playlist = data;
                    this.list = data.tracks;
                    let i = this.list.length;
                    while (i--) {
                        this.listShuffled[i] = this.list[i];
                    }
                    this.shuffling(this.listShuffled);
                    this.playlistLoaded.emit(data);
                });
            });
    };

    public tracks() { return this.playlist.tracks; }

    shuffling(o) {
        for (let j, x, i = o.length; i; j = Math.floor(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x) {
            ; // don't remove that!
        }
        return o;
    }
}
