import {
    AfterViewInit, Component, ContentChildren, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2,
    ViewChild
} from '@angular/core';
import { AudioService } from '../services/audio.service';
import {PlaylistService} from '../services/playlist.service';
import * as _ from 'underscore';
// import {forEach} from "@angular/router/src/utils/collection";
@Component({
    selector: 'div[spectrum]',
    template: '<canvas #spectrum></canvas>',
})
export class SpectrumComponent implements OnInit {
    @ViewChild('spectrum') canvasRef: ElementRef;

    allCapsReachBottom: boolean = false;
    animationId: any = null;
    maxValue: any = 0;
    ctx: CanvasRenderingContext2D;
    count = 0;
    renderer: Renderer2;

    constructor(private audioService: AudioService, renderer: Renderer2)
    {
      this.renderer = renderer;
    }

    draw() {
        const cwidth = 300,
            cheight = 150,
            gap = 2, // gap between meters
            meterNum = 32, // meter count, should be a multiple of 16
            fillValue = 0, // @TODO: set To 0 if analyser range is normalized
            gradient = this.ctx.createLinearGradient(0, 0, 300, 0);
        gradient.addColorStop(0, '#f00');
        gradient.addColorStop(0.17, '#ff0');
        gradient.addColorStop(0.34, '#0f0');
        gradient.addColorStop(0.51, '#0fffe7');
        gradient.addColorStop(0.68, '#0400ff');
        gradient.addColorStop(0.85, '#ff01a9');
        gradient.addColorStop(1, '#f00');
        const data = this.audioService.getFreqData(meterNum, 8);
        // this.consolelog(data);
        this.ctx.clearRect(0, 0, cwidth, cheight);
        const fill = 1 + fillValue / 100;
        const start = Math.ceil(meterNum - (meterNum - (fillValue / 2)));
        // let start = 0;
        const meterWidth = (((fill) ? (cwidth * fill) : cwidth) / meterNum - gap);
        const meterHeight = meterWidth / 8;
        for (let i = 0; i < meterNum; i++) {
            const h = ( (data[i]) * cheight) / (this.audioService.merger * 255);
            this.ctx.fillStyle = gradient; // set the filllStyle to gradient for a better look
            const cubeNb = Math.ceil( h / ( meterHeight + gap ) );
            for ( let j = 0; j < cubeNb; j++ ) {
                this.ctx.fillRect(
                    (i * (meterWidth + gap)) - ( cwidth * (start / 100) ),
                    cheight - (j * (meterHeight + gap / 2)),
                    meterWidth,
                    -meterHeight
                );
            }
            // start++;
        }
        requestAnimationFrame(() => this.draw());
    }

    ngOnInit() {
        this.audioService.audioReady.subscribe(() => {
            this.ctx = this.canvasRef.nativeElement.getContext('2d');
            this.renderer.setStyle(this.canvasRef.nativeElement, 'width', '100%');
            this.renderer.setStyle(this.canvasRef.nativeElement, 'height', '100%');
            this.draw();
        });
    }
}

