<div class="view">
    <div class="promote-section" fxLayout="column" fxLayoutAlign="center center">
        <div class="headline">
          <h1 class="mat-h mat-display-41"><strong>BrokenViz</strong></h1>
          <hr class="hr-light my-4 wow fadeInDown">
          <h2 class="">Discover the broken show.</h2>
        </div>
        <div class="headline-start">
          <a mat-flat-button class="" [routerLink]="['', { outlets: { visualization: ['visualizer', 'hopalong'] } }]"  queryParamsHandling="merge">Launch</a>
<!--          <a mat-flat-button class="" routerLink="/visualizer" queryParamsHandling="merge" skipLocationChange>Launch</a>-->
        </div>
    </div>
</div>
