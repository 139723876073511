import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {TokenService} from '../services/token.service';
import {AuthService} from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private tokenService: TokenService,
        private authService: AuthService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token = this.tokenService.getToken();
        const refreshToken = this.tokenService.getRefreshToken();

        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'OAuth ' + token
                }
            });
        }

        if (!request.headers.has('Content-Type')) {
            request = request.clone({
                setHeaders: {
                    'content-type': 'application/json'
                }
            });
        }

        request = request.clone({
            headers: request.headers.set('Accept', 'application/json')
        });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    console.log('event--->>>', event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                console.error(error.error.message);
                console.error(error.error);
                console.error(error);
                if (error.status === 401) {
                    if (error.error.error === 'invalid_token' || error.error.status === '401 - Unauthorized') {
                        if (refreshToken !== null) {
                            this.authService.refreshToken({refresh_token: refreshToken})
                                .subscribe(() => {
                                    location.reload();
                                });
                        } else {
                            this.authService.login()
                                .subscribe(() => {
                                    location.reload();
                                });
                        }
                    }/* else {
                        this.router.navigate(['login']).then(_ => console.log('redirect to login'));
                    }*/
                }
                if (error.status === 0) {
                    // if (error.error.error === 'invalid_token') {
                        this.authService.login()
                            .subscribe(() => {
                                location.reload();
                            });
                    /*} else {
                        this.router.navigate(['login']).then(_ => console.log('redirect to login'));
                    }*/
                }
                return throwError(error);
            }));
    }
}
