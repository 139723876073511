import {ModuleWithProviders} from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HopalongComponent} from '../components/visualisations/hopalong.component';
import {CloudComponent} from '../components/visualisations/cloud.component';
import {HedgehogComponent} from '../components/visualisations/hedgehog.component';
import {LotusComponent} from '../components/visualisations/lotus.component';
import {AngelsComponent} from '../components/visualisations/angels.component';
import {VisualizerModule} from './visualizer.module';


export const routes: Routes = [
  { path: '',   redirectTo: 'hopalong', pathMatch: 'full'},
  { path: 'hopalong', component: HopalongComponent, data : {animation: 'hopalong'} },
  { path: 'cloud', component: CloudComponent, data : {animation: 'cloud'}},
  // { path: 'particles', component: ParticlesComponent },
  { path: 'hedgehog', component: HedgehogComponent, data : {animation: 'hedgehog'} },
  { path: 'lotus', component: LotusComponent, data : {animation: 'lotus'} },
  { path: 'angels', component: AngelsComponent, data : {animation: 'angels'} },
  // { path: '**', component: PageNotFoundComponent }
];

export const routing: ModuleWithProviders<VisualizerModule> = RouterModule.forChild(routes);
